import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import TeamGroup from "../../../components/team/Team";

const Team = () => {
    return (
        <div>
            <Helmet>
                <title>Multi Cloud Ai - Team</title>
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderDefault />
            {/* End Header */}

            <div className="ptf-main">
                <div className="ptf-page ptf-page--team">
                    <section>
                        {/* <!--Spacer--> */}
                        <div
                            className="ptf-spacer"
                            style={{ "--ptf-xxl": "5rem", "--ptf-md": "5rem" }}
                        ></div>
                        <div className="container-xxl">
                            <div className="row">
                                <div className="col-xl-10">
                                    {/* <!--Animated Block--> */}
                                    <div
                                        className="ptf-animated-block"
                                        data-aos="fade"
                                        data-aos-delay="0"
                                    >
                                        <h1 className="large-heading">The face of McAi</h1>
                                        {/* <!--Spacer--> */}
                                        <div
                                            className="ptf-spacer"
                                            style={{ "--ptf-xxl": "2.5rem" }}
                                        ></div>
                                        <p className="fz-24">
                                            Meet our talents team of creative, fun-lovers and magic
                                            makers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* End .row */}

                            {/* <!--Spacer--> */}
                            <div
                                className="ptf-spacer"
                                style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                            ></div>

                            <div className="ptf-team-member-grid">
                                <TeamGroup />
                            </div>
                        </div>
                        {/* <!--Spacer--> */}
                        <div
                            className="ptf-spacer"
                            style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
                        ></div>
                    </section>
                </div>
            </div>

            {/*=============================================
                           Start Footer section
                          ============================================== */}
            {/* <!--Footer--> */}
            <footer className="ptf-footer ptf-footer--style-1">
                <div className="container-xxl">
                    <div className="ptf-footer__top">
                        <Footer />
                    </div>
                    <div className="ptf-footer__bottom">
                        <CopyRight />
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Team;
